import { ExclamationCircleOutlined } from "@ant-design/icons";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { UnpackedArray } from "@app/types/util.types";
import { Modal, Tabs, TabsProps } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import isEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { Candidates } from "../../../Candidates/Candidates";
import { CompanyAdminInfoForm } from "./components/CompanyAdminInfoForm/CompanyAdminInfoForm";
import { UserAdvancedForm } from "./components/UserAdvancedForm/UserAdvancedForm";
import { UserCertificates } from "./components/UserCertificates/UserCertificates";
import { UserHighlights } from "./components/UserHighlights/UserHighlights";
import { UserInfoForm } from "./components/UserInfoForm/UserInfoForm";
import { UserRelevantJobs } from "./components/UserRelevantJobs/UserRelevantJobs";
import { UserStats } from "./components/UserStats/UserStats";
import { UserTimeline } from "./components/UserTimeline/UserTimeline";
const { confirm } = Modal;

type UserModalProps = {
  open: boolean;
  selectedUser?: UserFormDef;
  onSave: () => void;
  onCancel: () => void;
};
enum TAB_KEYS {
  INFO = "info",
  TIMELINE = "timeline",
  CERTIFICATE = "certificate",
  HIGHLIGHTS = "highlights",
  STATS = "stats",
  APPLICATIONS = "applications",
  RELEVANT_JOBS = "relevant-jobs",
  ADVANCED = "advanced",
}

export const UserModal = ({ open, selectedUser, onSave, onCancel }: UserModalProps) => {
  const { t } = useTranslation();
  // forms
  const [form] = useForm<UserFormDef>();
  const [activeTab, setActiveTab] = useState<TAB_KEYS>(TAB_KEYS.INFO);
  const [statsHasChangedValues, setStatsHasChangedValues] = useState(false);

  const showConfirmModal = (callback: () => void, isStats = false) => {
    confirm({
      title: t(
        isStats
          ? "You have unsaved stats, are you sure you want to discard them?"
          : "You have unsaved changes, are you sure you want to leave?"
      ),
      icon: <ExclamationCircleOutlined />,
      closable: true,
      maskClosable: true,
      cancelText: t("Cancel"),
      onOk: callback,
      onCancel: () => null,
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [JSON.stringify(selectedUser)]);

  const handleOnCancel = () => {
    const allValues = form.getFieldsValue(true);

    if (!isEqual(selectedUser, allValues) || statsHasChangedValues) {
      showConfirmModal(onCancel, statsHasChangedValues);
    } else {
      onCancel();
    }
  };

  const handleOnSave = () => {
    onSave();
  };

  const onChangeTab = (key: string) => {
    if (activeTab === TAB_KEYS.STATS && statsHasChangedValues) {
      showConfirmModal(() => setActiveTab(key as TAB_KEYS), statsHasChangedValues);
    } else {
      setActiveTab(key as TAB_KEYS);
    }
  };

  const changeCandidateSettings = async () => {
    if (statsHasChangedValues) {
      showConfirmModal(form.submit, statsHasChangedValues);
    } else {
      form.submit();
    }
  };
  const handleReset = () => {
    form.resetFields();
    setActiveTab(TAB_KEYS.INFO);
  };

  let tabItems: (UnpackedArray<TabsProps["items"]> & {
    modalWidth?: string | number;
  })[] = [];

  if (selectedUser?.isCompanyAdmin) {
    tabItems = [
      {
        key: TAB_KEYS.INFO,
        label: t("Info"),
        children: (
          <CompanyAdminInfoForm form={form} onSave={handleOnSave} selectedUser={selectedUser} />
        ),
        forceRender: true,
        destroyInactiveTabPane: false,
      },
      {
        key: TAB_KEYS.ADVANCED,
        label: t("Advanced"),
        children: <UserAdvancedForm selectedUser={selectedUser} />,
        forceRender: false,
        destroyInactiveTabPane: false,
      },
    ];
  } else {
    tabItems = [
      {
        key: TAB_KEYS.INFO,
        label: t("Info"),
        children: <UserInfoForm form={form} onSave={handleOnSave} selectedUser={selectedUser} />,
        forceRender: true,
        destroyInactiveTabPane: false,
      },
      {
        key: TAB_KEYS.TIMELINE,
        label: t("Timeline"),
        children: <UserTimeline selectedUser={selectedUser} />,
        forceRender: false,
        destroyInactiveTabPane: true,
      },
      {
        key: TAB_KEYS.CERTIFICATE,
        label: t("Certificates"),
        children: <UserCertificates selectedUser={selectedUser} />,
        forceRender: false,
        destroyInactiveTabPane: true,
      },
      {
        key: TAB_KEYS.HIGHLIGHTS,
        label: t("Site Photos"),
        children: <UserHighlights selectedUser={selectedUser} />,
        forceRender: false,
        destroyInactiveTabPane: true,
      },
      {
        key: TAB_KEYS.STATS,
        label: t("Stats"),
        children: (
          <UserStats onChangedValues={setStatsHasChangedValues} selectedUser={selectedUser} />
        ),
        forceRender: false,
        destroyInactiveTabPane: true,
      },
      {
        key: TAB_KEYS.APPLICATIONS,
        label: t("applications-label"),
        children: <Candidates selectedUser={selectedUser} />,
        forceRender: false,
        destroyInactiveTabPane: true,
        modalWidth: "100%",
      },
      {
        key: TAB_KEYS.RELEVANT_JOBS,
        label: t("Relevant Jobs"),
        children: <UserRelevantJobs selectedUser={selectedUser} />,
        forceRender: false,
        destroyInactiveTabPane: true,
        modalWidth: "100%",
      },
      {
        key: TAB_KEYS.ADVANCED,
        label: t("Advanced"),
        children: <UserAdvancedForm selectedUser={selectedUser} />,
        forceRender: false,
        destroyInactiveTabPane: false,
      },
    ];
  }

  const activeTabItem = tabItems.find((item) => item.key === activeTab);

  return (
    <Modal
      title={t("Edit")}
      open={open}
      onCancel={handleOnCancel}
      width={activeTabItem && "modalWidth" in activeTabItem ? activeTabItem.modalWidth : 800}
      onOk={changeCandidateSettings}
      okButtonProps={{ size: "large" }}
      cancelButtonProps={{ size: "large" }}
      forceRender
      destroyOnClose
      afterClose={handleReset}
    >
      <Tabs
        activeKey={activeTab}
        onChange={onChangeTab}
        items={tabItems}
        destroyInactiveTabPane={true}
      />
    </Modal>
  );
};
