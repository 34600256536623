import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { ENV } from "@app/constants/env";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import {
  CandidateDef,
  CandidatesColumnsDataDef,
  GetCandidatesParamsDef,
} from "@app/features/super-admin/types/super-admin.candidate.types";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState, store } from "@app/store/store";
import { ECandidateMethod, ECandidateStatus } from "@app/types/candidate.types";
import { Col, Row, Space, Table, TableColumnsType, Typography } from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMoment from "react-moment";
import { useSelector } from "react-redux";
import { CandidateDrawer } from "./components/CandidateDrawer/CandidateDrawer";

type Props = {
  selectedUser?: UserFormDef;
};

export const Candidates = ({ selectedUser }: Props) => {
  const { t, i18n } = useTranslation();
  const { dispatch } = store;
  const [showCandidateDrawer, setShowCandidateDrawer] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateDef | undefined>();
  // table data
  const candidates = useSelector((state: RootState) => state.superAdmin.candidates);
  const candidatesCount = useSelector((state: RootState) => state.superAdmin.candidatesCount);
  const [page, setPage] = useState(0);
  // loading states
  const candidatesLoading = useSelector(
    (state: RootState) => state.loading.effects.superAdmin.getCandidates
  );
  // filter data
  const [searchCompaniesQuery, setSearchCompaniesQuery] = useState<string>("");
  const companies = useSelector((state: RootState) => state.superAdmin.companies);
  const companyChoices = companies.map((i) => ({
    value: i.id,
    label: i.name,
    id: i.id,
  }));
  const [searchUsersQuery, setSearchUsersQuery] = useState<string>("");
  const users = useSelector((state: RootState) => state.superAdmin.users);
  const userChoices = users.map((i) => ({
    value: i.id,
    label: `${i.firstName} ${i.lastName} - ${i.phone || i.email}`,
    id: i.id,
  }));
  // filter params
  const [companyIdForFilter, setCompanyIdForFilter] = useState<string>();
  const [userIdForFilter, setUserIdForFilter] = useState<string | undefined>(selectedUser?.id);
  const showFilters = !selectedUser;

  const filterParams: Partial<GetCandidatesParamsDef> = {
    companyId: companyIdForFilter,
    workerProfileId: userIdForFilter,
  };

  const debouncedSearchCompany = _debounce((value: string) => {
    setSearchCompaniesQuery(value);
  }, 300);

  const onSearchCompanies = (value: string) => {
    debouncedSearchCompany(value);
  };

  const debouncedSearchUser = _debounce((value: string) => {
    setSearchUsersQuery(value);
  }, 300);

  const onSearchUsers = (value: string) => {
    debouncedSearchUser(value);
  };

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      setPage(pagination.current - 1);
      dispatch.superAdmin.getCandidates({
        limit: SUPER_ADMIN_PAGE_SIZE,
        offset: pagination.current - 1,
        ...filterParams,
      });
    }
  };

  const generatedData = (data: CandidateDef[]): CandidatesColumnsDataDef[] => {
    return data?.map((item) => ({
      key: item.id,
      companyName: (
        <a
          href={`${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/companies/${
            item.crafthuntCompany.slug
          }`}
          target="_blank"
          rel="noreferrer"
        >
          {item.crafthuntCompany.name}
        </a>
      ),
      jobAds: item.jobAds,
      jobName:
        item.jobAds.length === 1 ? (
          <a
            href={`${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/jobs/${
              item.jobAds[0].slug
            }`}
            target="_blank"
            rel="noreferrer"
          >
            {item.jobAds[0].title}
          </a>
        ) : item.jobAds.length > 1 ? (
          ` ${item.jobAds.length} ${t("Job ads")}`
        ) : (
          ""
        ),
      candidateName: !item.workerProfile.deletedAt ? (
        <a
          href={`${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/craftsmen/${
            item.workerProfile.slug
          }`}
          target="_blank"
          rel="noreferrer"
        >
          {[item.workerProfile.firstName, item.workerProfile.lastName].join(" ")}
        </a>
      ) : (
        [item.workerProfile.firstName, item.workerProfile.lastName].join(" ")
      ),
      status:
        item.companyCandidateStatus?.status === ECandidateStatus.CUSTOM
          ? item.companyCandidateStatus.name
          : t(item.companyCandidateStatus?.name || ""),
      method: item.isCrafthuntRecommended
        ? "Recommended"
        : item.method === ECandidateMethod.APPLIED
        ? "Applied"
        : "Active Source",
      createdAt: <ReactMoment fromNow>{item.createdAt}</ReactMoment>,
      operation: (
        <a
          onClick={() => {
            setSelectedCandidate(item);
            setShowCandidateDrawer(true);
          }}
        >
          {t("Details")}
        </a>
      ),
    }));
  };

  const columns: TableColumnsType<CandidatesColumnsDataDef> = [
    {
      title: t("Company name"),
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
      width: 250,
    },
    { title: t("Job ads"), dataIndex: "jobName", key: "jobName", ellipsis: true, width: 250 },
    {
      title: t("candidate-name-label"),
      dataIndex: "candidateName",
      key: "candidateName",
      ellipsis: true,
      width: 200,
    },
    { title: t("Status"), dataIndex: "status", key: "status", ellipsis: true, width: 150 },
    { title: "Method", dataIndex: "method", key: "method", ellipsis: true, width: 150 },
    { title: t("Created at"), dataIndex: "createdAt", key: "createdAt" },
    { title: t("Actions"), dataIndex: "operation", key: "operation", fixed: "right", width: 100 },
  ];

  useEffect(() => {
    dispatch.superAdmin.getCompanies({ keyword: searchCompaniesQuery, isPaying: true });
  }, [searchCompaniesQuery]);

  useEffect(() => {
    dispatch.superAdmin.getUsers({ keyword: searchUsersQuery });
  }, [searchUsersQuery]);

  useEffect(() => {
    dispatch.superAdmin.getCandidates({
      limit: SUPER_ADMIN_PAGE_SIZE,
      offset: 0,
      ...filterParams,
    });
    setPage(0);
  }, [JSON.stringify(filterParams)]);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {showFilters && (
        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <SelectAutocomplete
              placeholder={t("Companies")}
              options={companyChoices}
              onSearch={onSearchCompanies}
              onChange={setCompanyIdForFilter}
              allowClear
            />
          </Col>
          <Col span={24}>
            <SelectAutocomplete
              value={userIdForFilter}
              placeholder={t("Users")}
              options={userChoices}
              onSearch={onSearchUsers}
              onChange={setUserIdForFilter}
              allowClear
            />
          </Col>
        </Row>
      )}
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={generatedData(candidates)}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Typography.Text>{t("Job ads")}</Typography.Text>
              <ul>
                {record.jobAds.map((job) => (
                  <li key={job.id}>
                    <a
                      href={`${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/jobs/${
                        job.slug
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {job.title}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          ),
          rowExpandable: (record) => record.jobAds.length > 1,
        }}
        loading={candidatesLoading}
        pagination={{
          current: page + 1,
          total: candidatesCount,
          showTotal: (total) => `${t("Total")} ${total} ${t("applications-label")}`,
          pageSize: SUPER_ADMIN_PAGE_SIZE,
          showSizeChanger: false,
          position: ["bottomLeft"],
        }}
        onChange={handlePageChange}
        scroll={{ x: 1300 }}
      />
      {!!selectedCandidate && (
        <CandidateDrawer
          key={selectedCandidate.id}
          open={showCandidateDrawer}
          onClose={() => setShowCandidateDrawer(false)}
          candidate={selectedCandidate}
        />
      )}
    </Space>
  );
};
