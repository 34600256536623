import { ENV } from "@app/constants/env";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import { CompanyDef } from "@app/features/super-admin/types/super-admin.company.types";
import { EngagementColumnsDataDef } from "@app/features/super-admin/types/super-admin.engagement.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState, store } from "@app/store/store";
import { Input, Modal, Space, Table, TableColumnsType, TablePaginationConfig } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EngagementStatus } from "./components/EngagementStatus";
const { Search } = Input;

type EngagementModalProps = {
  open: boolean;
  selectedUserId?: string;
  onClose: () => void;
};

export const EngagementModal = ({ open, selectedUserId, onClose }: EngagementModalProps) => {
  const { t, i18n } = useTranslation();
  const { dispatch } = store;
  const [keyword, setKeyword] = useState("");
  const companies = useSelector((state: RootState) => state.superAdmin.companies);
  const companiesCount = useSelector((state: RootState) => state.superAdmin.companiesCount);
  const companiesLoading = useSelector((state: RootState) => state.loading.models.superAdmin);

  const columns: TableColumnsType<EngagementColumnsDataDef> = [
    { title: t("Company name"), dataIndex: "companyName", key: "companyName", width: 150 },
    { title: t("Status"), dataIndex: "status", key: "status", fixed: "right", width: 100 },
  ];

  const generatedData = (data: CompanyDef[]): EngagementColumnsDataDef[] => {
    return data.map((item) => ({
      key: item.id,
      companyName: (
        <a
          href={`${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/companies/${item.slug}`}
          target="_blank"
          rel="noreferrer"
        >
          {item.name}
        </a>
      ),
      status: <EngagementStatus companyId={item.id} userId={selectedUserId} />,
    }));
  };

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      dispatch.superAdmin.getCompanies({
        limit: SUPER_ADMIN_PAGE_SIZE,
        offset: pagination.current - 1,
        isPaying: true,
        keyword,
      });
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    dispatch.superAdmin.getCompanies({
      limit: SUPER_ADMIN_PAGE_SIZE,
      offset: 0,
      isPaying: true,
      keyword,
    });
  }, [keyword]);

  return (
    <Modal
      title={t("engagement-modal-title")}
      open={open}
      onOk={handleOnClose}
      onCancel={handleOnClose}
      width="90%"
      okButtonProps={{ size: "large" }}
      cancelButtonProps={{ size: "large" }}
    >
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Search
          placeholder={t("Search for company id, company name or contact email")}
          enterButton={t("Search")}
          size="large"
          onSearch={setKeyword}
          allowClear
        />
        <Table
          style={{ width: "100%" }}
          loading={companiesLoading}
          columns={columns}
          scroll={{ x: 1270 }}
          dataSource={generatedData(companies)}
          pagination={{
            total: companiesCount,
            showTotal: (total) => `${t("Total")} ${total} ${t("Companies")}`,
            pageSize: SUPER_ADMIN_PAGE_SIZE,
            showSizeChanger: false,
          }}
          onChange={handlePageChange}
        />
      </Space>
    </Modal>
  );
};
