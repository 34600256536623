import { getBearerToken, parseJwt } from "@app/features/auth/helpers/auth.helper";
import { disableAnalytics } from "@app/helpers/analytics.helper";
import { createModel } from "@rematch/core";
import axios from "axios";
import { RootModel } from "../../../store/models/models";
import { superAdminApi, superAdminApiCached } from "../api/super-admin.api";
import { getCandidates } from "../api/super-admin.candidate.api";
import { superAdminCheck } from "../api/super-admin.check.api";
import { getCompanies, loginCompany, updateCompanySettings } from "../api/super-admin.company.api";
import { getJobs } from "../api/super-admin.job.api";
import { getUsers, updateUser } from "../api/super-admin.user.api";
import { SUPER_TOKEN_KEY } from "../constants/super-admin.constants";
import { CandidateDef, GetCandidatesParamsDef } from "../types/super-admin.candidate.types";
import {
  CompanyDef,
  GetCompaniesParamsDef,
  LoginAsAdminDef,
  LoginUnclaimCompanyDef,
} from "../types/super-admin.company.types";
import { GetJobsParamsDef, JobDef } from "../types/super-admin.job.types";
import {
  EditUserDef,
  GetUsersParamsDef,
  UserFormDef,
  UsersResponseDef,
} from "../types/super-admin.user.types";

export const superAdmin = createModel<RootModel>()({
  state: {
    superToken: localStorage.getItem(SUPER_TOKEN_KEY) as string | null,
    parsedSuperToken: parseJwt(localStorage.getItem(SUPER_TOKEN_KEY) as string),
    companies: [] as CompanyDef[],
    companiesCount: 0,
    jobs: [] as JobDef[],
    jobsCount: 0,
    candidates: [] as CandidateDef[],
    candidatesCount: 0,
    users: [] as UsersResponseDef[],
    usersCount: 0,
    selectedUser: undefined as UserFormDef | undefined,
  },
  reducers: {
    setSuperAdminToken: (state, payload: string) => {
      localStorage.setItem("super-token", payload);
      const parsed = parseJwt(payload);
      if (!parsed) {
        localStorage.removeItem("super-token");
        return;
      }
      superAdminApi.defaults.headers.common.Authorization = getBearerToken(payload);
      superAdminApiCached.defaults.headers.common.Authorization = getBearerToken(payload);
      // Disable analytics when super admin token is set
      disableAnalytics();
      state.superToken = payload;
      state.parsedSuperToken = parsed;
      return state;
    },
    removeSuperAdminToken: (state) => {
      localStorage.removeItem("super-token");
      delete superAdminApi.defaults.headers.common.Authorization;
      delete superAdminApiCached.defaults.headers.common.Authorization;
      state.superToken = null;
      state.parsedSuperToken = undefined;
      return state;
    },
    setCompanies: (
      state,
      payload: {
        companies: CompanyDef[];
        companiesCount: number;
      }
    ) => {
      state.companies = payload.companies;
      state.companiesCount = payload.companiesCount;
      return state;
    },
    setJobs: (
      state,
      payload: {
        jobs: JobDef[];
        jobsCount: number;
      }
    ) => {
      state.jobs = payload.jobs;
      state.jobsCount = payload.jobsCount;
      return state;
    },
    setCandidates: (
      state,
      payload: {
        candidates: CandidateDef[];
        candidatesCount: number;
      }
    ) => {
      state.candidates = payload.candidates;
      state.candidatesCount = payload.candidatesCount;
      return state;
    },
    setUsers: (
      state,
      payload: {
        users: UsersResponseDef[];
        usersCount: number;
      }
    ) => {
      state.users = payload.users;
      state.usersCount = payload.usersCount;
      return state;
    },
    setSelectedUser: (state, payload: UserFormDef | undefined) => {
      state.selectedUser = payload;
      return state;
    },
    updateCompany: (state, payload: { companyData: CompanyDef; index: number }) => {
      state.companies.splice(payload.index, 1, payload.companyData);
      return state;
    },
  },
  effects: (dispatch) => ({
    superAdminCheck: async (password) => {
      try {
        const res = await superAdminCheck(password);
        if (res.data?.accessToken) {
          dispatch.superAdmin.setSuperAdminToken(res.data.accessToken);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    getCompanies: async (payload: GetCompaniesParamsDef) => {
      try {
        const res = await getCompanies(payload);
        dispatch.superAdmin.setCompanies({
          companies: res.data.data,
          companiesCount: res.data.count,
        });
      } catch (e) {
        console.error(e);
        if (axios.isAxiosError(e) && e.response?.status === 401) {
          dispatch.superAdmin.clearAdminData();
          return;
        }
        throw e;
      }
    },
    getJobs: async (payload: GetJobsParamsDef) => {
      try {
        const res = await getJobs(payload);
        dispatch.superAdmin.setJobs({
          jobs: res.data.data,
          jobsCount: res.data.count,
        });
      } catch (e) {
        console.error(e);
        if (axios.isAxiosError(e) && e.response?.status === 401) {
          dispatch.superAdmin.clearAdminData();
          return;
        }
        throw e;
      }
    },
    getCandidates: async (payload: GetCandidatesParamsDef) => {
      try {
        const res = await getCandidates(payload);
        dispatch.superAdmin.setCandidates({
          candidates: res.data.data,
          candidatesCount: res.data.count,
        });
      } catch (e) {
        console.error(e);
        if (axios.isAxiosError(e) && e.response?.status === 401) {
          dispatch.superAdmin.clearAdminData();
          return;
        }
        throw e;
      }
    },
    getUsers: async (payload: GetUsersParamsDef) => {
      try {
        const res = await getUsers(payload);
        dispatch.superAdmin.setUsers({
          users: res.data.data,
          usersCount: res.data.count,
        });
      } catch (e) {
        console.error(e);
        if (axios.isAxiosError(e) && e.response?.status === 401) {
          dispatch.superAdmin.clearAdminData();
          return;
        }
        throw e;
      }
    },
    clearAdminData: () => {
      dispatch.superAdmin.removeSuperAdminToken();

      dispatch.superAdmin.setCompanies({
        companies: [],
        companiesCount: 0,
      });
      dispatch.superAdmin.setJobs({
        jobs: [],
        jobsCount: 0,
      });
      dispatch.superAdmin.setUsers({
        users: [],
        usersCount: 0,
      });
      dispatch.superAdmin.setCandidates({
        candidates: [],
        candidatesCount: 0,
      });
    },
    loginCompany: async (payload: LoginAsAdminDef | LoginUnclaimCompanyDef) => {
      try {
        let token;
        if ((payload as LoginAsAdminDef).targetWorkerProfileId) {
          token = await loginCompany(payload as LoginAsAdminDef);
        } else {
          token = await loginCompany(payload as LoginUnclaimCompanyDef);
        }
        await dispatch.auth.setTokenData(token.data.accessToken);
        // just in case, clear super admin jobs
        dispatch.jobAds.clearAllJobAdAction();
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    changeCompanySettings: async (payload: CompanyDef, rootState) => {
      const companyIndex = rootState.superAdmin.companies.findIndex((i) => i.id === payload.id);
      if (companyIndex > -1) {
        const res = await updateCompanySettings(payload);
        dispatch.superAdmin.updateCompany({
          companyData: { ...payload },
          index: companyIndex,
        });
        return { ...payload, ...res };
      }
    },
    updateUser: (payload: Partial<EditUserDef>) => {
      return updateUser(payload);
    },
  }),
});
