import { AddressPicker, EAddressPickerType } from "@app/components/ui/AddressPicker/AddressPicker";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { StatusBadge } from "@app/components/ui/StatusBadge/StatusBadge";
import { ENV } from "@app/constants/env";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import {
  GetJobsParamsDef,
  JobDef,
  JobsColumnsDataDef,
} from "@app/features/super-admin/types/super-admin.job.types";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState, store } from "@app/store/store";
import { AddressObjDef } from "@app/types/address.types";
import { EJobAdStatus } from "@app/types/job-ads.types";
import { Col, Input, Row, Space, Switch, Table, TableColumnsType } from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const { Search } = Input;

export const Jobs = () => {
  const { t, i18n } = useTranslation();
  const { dispatch } = store;
  // table data
  const jobs = useSelector((state: RootState) => state.superAdmin.jobs);
  const jobsCount = useSelector((state: RootState) => state.superAdmin.jobsCount);
  const [page, setPage] = useState(0);
  // loading states
  const jobsLoading = useSelector((state: RootState) => state.loading.effects.superAdmin.getJobs);
  // filter params
  const [keyword, setKeyword] = useState("");
  const [onlyShowPublished, setOnlyShowPublished] = useState(true);
  const [selectedTradeIds, setSelectedTradeIds] = useState([]);
  const [selectedJobTitleIds, setSelectedJobTitleIds] = useState([]);
  const [coordinates, setCoordinates] = useState("");
  // filter data
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  const jobTitleChoices = jobTitles.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  const tradesChoices = availableTradesTags.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));

  const filterParams: Partial<GetJobsParamsDef> = {
    title: keyword,
    status: onlyShowPublished ? "published" : undefined,
    tradeIds: selectedTradeIds,
    jobTitleIds: selectedJobTitleIds,
    coordinates: coordinates,
  };

  const handleAddressChange = (address: AddressObjDef | null) => {
    if (address?.coordinates) {
      setCoordinates(`${address.coordinates.lat},${address.coordinates.lng}`);
    } else {
      setCoordinates("");
    }
  };

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      setPage(pagination.current - 1);
      dispatch.superAdmin.getJobs({
        limit: SUPER_ADMIN_PAGE_SIZE,
        offset: pagination.current - 1,
        ...filterParams,
      });
    }
  };

  const generatedData = (data: JobDef[]): JobsColumnsDataDef[] => {
    return data?.map((item) => ({
      key: item.id,
      status: (
        <StatusBadge status={t(item.status)} isSuccess={item.status === EJobAdStatus.PUBLISHED} />
      ),
      companyName: (
        <a
          href={`${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/companies/${
            item.crafthuntCompany.slug
          }`}
          target="_blank"
          rel="noreferrer"
        >
          {item.crafthuntCompany.name}
        </a>
      ),
      jobName: (
        <a
          href={`${ENV.WEB_URL}/${convertLangForBackend(i18n.language)}/jobs/${item.slug}`}
          target="_blank"
          rel="noreferrer"
        >
          {item.title}
        </a>
      ),
      role: t(item.role.name),
      trade: t(item.trade.name),
      location:
        item.address.countryCode === "DE"
          ? item.address.city
          : [item.address.city, item.address.country].filter(Boolean).join(", "),
      distance:
        typeof item.distance === "number"
          ? `${item.distance.toFixed(1).replace(".0", "")} km`
          : "-",
    }));
  };

  const columns: TableColumnsType<JobsColumnsDataDef> = [
    { title: null, dataIndex: "status", key: "status", width: 25 },
    { title: t("Job title"), dataIndex: "jobName", key: "jobName", ellipsis: true },
    {
      title: t("Company name"),
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
      width: 250,
    },
    { title: t("Role"), dataIndex: "role", key: "role", ellipsis: true, width: 150 },
    { title: t("Trade"), dataIndex: "trade", key: "trade", ellipsis: true, width: 150 },
    { title: t("Address"), dataIndex: "location", key: "location", ellipsis: true, width: 200 },
    {
      title: t("distance-label"),
      dataIndex: "distance",
      key: "distance",
      ellipsis: true,
      width: 125,
    },
  ];

  useEffect(() => {
    dispatch.superAdmin.getJobs({
      limit: SUPER_ADMIN_PAGE_SIZE,
      offset: 0,
      ...filterParams,
    });
    setPage(0);
  }, [JSON.stringify(filterParams)]);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Search
        placeholder={t("job-ads-search-placeholder")}
        enterButton={t("Search")}
        size="large"
        onSearch={setKeyword}
        allowClear
      />
      <Row gutter={[16, 16]} align="middle">
        <Col span={6}>
          <AddressPicker type={EAddressPickerType.CITY} disableMap onChange={handleAddressChange} />
        </Col>
        <Col span={6}>
          <SelectAutocomplete
            mode="multiple"
            placeholder={t("roles-select")}
            options={jobTitleChoices}
            onChange={(value) => setSelectedJobTitleIds(value)}
          />
        </Col>
        <Col span={6}>
          <SelectAutocomplete
            mode="multiple"
            placeholder={t("Trades")}
            options={tradesChoices}
            onChange={(value) => setSelectedTradeIds(value)}
          />
        </Col>
        <Col span={6}>
          <label htmlFor="onlyPublished" style={{ cursor: "pointer", marginRight: 8 }}>
            {t("only-published-toggle-label")}
          </label>
          <Switch
            id="onlyPublished"
            checked={onlyShowPublished}
            onChange={() => setOnlyShowPublished(!onlyShowPublished)}
          />
        </Col>
      </Row>
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={generatedData(jobs)}
        loading={jobsLoading}
        pagination={{
          current: page + 1,
          total: jobsCount,
          showTotal: (total) => `${t("Total")} ${total} ${t("Job ads")}`,
          pageSize: SUPER_ADMIN_PAGE_SIZE,
          showSizeChanger: false,
          position: ["bottomLeft"],
        }}
        onChange={handlePageChange}
        scroll={{ x: 1150 }}
      />
    </Space>
  );
};
