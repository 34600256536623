import { CheckOutlined, CloseOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { PhoneNumberInput } from "@app/components/ui/PhoneNumberInput/PhoneNumberInput";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { SimpleAddressPicker } from "@app/components/ui/SimpleAddressPicker/SimpleAddressPicker";
import { UploaderFiles } from "@app/components/ui/UploaderFiles/UploaderFiles";
import { DateFormats } from "@app/constants/date.constants";
import { mapUserToApi } from "@app/features/super-admin/helpers/super-admin.helper";
import {
  DocumentTypeEnum,
  ExternalLinkTypeEnum,
  UserFormDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { triggerAnalyticsEvent } from "@app/helpers/analytics.helper";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState, store } from "@app/store/store";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Typography,
  message,
} from "antd";
import { Rule } from "antd/lib/form";
import { FormInstance, useWatch } from "antd/lib/form/Form";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";

type UserInfoFormProps = {
  form: FormInstance<UserFormDef>;
  selectedUser?: UserFormDef;
  onSave: () => void;
};

const DEFAULT_DOC_TYPE = DocumentTypeEnum.CV;

export const UserInfoForm = ({ form, selectedUser, onSave }: UserInfoFormProps) => {
  const { t, i18n } = useTranslation();
  const { dispatch } = store;
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  // loading states
  const loading = useSelector((state: RootState) => state.loading.effects.superAdmin.updateUser);
  // data
  const emailValue = useWatch("email", form);
  const phoneValue = useWatch("phone", form);
  const medias = useWatch("medias", form);
  const jobTitleChoices = jobTitles.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  const tradesChoices = availableTradesTags.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  const requiredRule: Rule = { required: true, whitespace: true, message: t("required") };
  const canEditPhone = !selectedUser?.phone;
  const canEditEmail = !selectedUser?.email;

  const getDocumentType = (fileUrl?: string) => {
    if (!fileUrl) {
      return DEFAULT_DOC_TYPE;
    }
    return medias?.find((doc) => doc.url === fileUrl)?.type || DEFAULT_DOC_TYPE;
  };

  const handleChangeDocumentType = (value: string, fileUrl?: string) => {
    if (!medias || !fileUrl) {
      return;
    }
    const docIndex = medias.findIndex((doc) => doc.url === fileUrl);
    if (docIndex !== -1) {
      const newMedias = JSON.parse(JSON.stringify(medias));
      newMedias[docIndex].type = value;
      form.setFieldValue("medias", newMedias);
    }
  };

  const onSubmitForm = async (values: UserFormDef) => {
    if (selectedUser) {
      try {
        const userWasReviewed = selectedUser.isReviewed;
        const isReviewed = values.isReviewed;
        const originalNumberOfDocuments = selectedUser.medias?.length || 0;
        const numberOfDocuments = values.medias?.length || 0;

        await dispatch.superAdmin.updateUser({
          ...mapUserToApi(values),
          id: selectedUser.id,
          lang: convertLangForBackend(i18n.language),
          email: canEditEmail ? values.email : undefined,
          phone: canEditPhone ? values.phone : undefined,
        });

        // Events
        if (userWasReviewed && !isReviewed) {
          triggerAnalyticsEvent("user_unreviewed", { userId: selectedUser.id });
        } else if (!userWasReviewed && isReviewed) {
          triggerAnalyticsEvent("user_reviewed", { userId: selectedUser.id });
        }
        if (numberOfDocuments > originalNumberOfDocuments) {
          triggerAnalyticsEvent("user_upload_document_adminpanel", { userId: selectedUser.id });
        }

        onSave();
        message.success({ content: t("Saved!"), key: "user-info" });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          message.error({
            content: getErrorMessages(error as ApiFormErrorDef),
            key: "user-info",
            duration: 10,
          });
        }
      }
    }
  };

  const onFormFailed = () => {
    message.error({ content: t("Please fix invalid fields"), key: "user-info" });
  };

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      labelAlign="right"
      form={form}
      initialValues={selectedUser}
      onFinish={onSubmitForm}
      onFinishFailed={onFormFailed}
      data-hs-do-not-collect="true"
    >
      {loading && <LoadingSpinner />}
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item label="ID" name="id">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label={t("First name")} name={"firstName"} rules={[requiredRule]}>
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={t("Last name")}
            name={"lastName"}
            rules={[{ ...requiredRule, whitespace: false }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={t("Email")}
            name={"email"}
            help={!canEditEmail ? "Contact tech team if you want to change" : ""}
            rules={[
              {
                type: "email",
                required: !phoneValue,
                whitespace: true,
                message: t("Required"),
              },
            ]}
          >
            <Input size="large" disabled={!canEditEmail} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={t("Mobile phone")}
            name={"phone"}
            help={!canEditPhone ? "Contact tech team if you want to change" : ""}
            rules={[
              {
                type: "string",
                required: !emailValue,
                whitespace: true,
                message: t("Required"),
              },
              {
                validator: (_, value) => {
                  if (value && !isPossiblePhoneNumber(value)) {
                    return Promise.reject(t("Not valid phone number"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneNumberInput placeholder={t("Enter phone number")} disabled={!canEditPhone} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item rules={[requiredRule]} label={t("Role")} name="jobTitleId" required>
            <SelectAutocomplete placeholder={t("Select")} options={jobTitleChoices} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ ...requiredRule, type: "array" as const }]}
            label={t("Trades")}
            name="profileTradesId"
            required
          >
            <SelectAutocomplete mode="multiple" placeholder={t("Select")} options={tradesChoices} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Birthday")} name="birthday">
            <DatePicker
              size="large"
              format={DateFormats.D_M_Y}
              allowClear
              placeholder={t("DD/MM/YYYY")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("About me")} name={"description"}>
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 4, maxRows: 8 }}
              style={{ width: "100%" }}
              placeholder={t("Enter...")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Current Address")} name="address">
            <SimpleAddressPicker />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Looking for Work - Address")} name="geoSearchAddress">
            <SimpleAddressPicker />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            label={t("Anonymous")}
            name={"isAnonymous"}
            valuePropName="checked"
            help={t(
              "This determines whether the user's data is anonymized (turned on) or revealed (turned off)."
            )}
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            label={t("Verified")}
            name={"isVerified"}
            valuePropName="checked"
            help={t(
              "This will show a 'Verified' badge on the user when turned on. This will also automatically turn 'Reviewed' on or off."
            )}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(checked) => form.setFieldValue("isReviewed", checked)}
            />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            label={t("Reviewed")}
            name={"isReviewed"}
            valuePropName="checked"
            help={t(
              "This determines whether the user is shown in explore page and will see jobs (turned on), or hidden in explore page and no jobs (turned off)."
            )}
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Typography.Title level={5}>{t("Internal stuff")}</Typography.Title>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t("Comment")}
            name={"internalComment"}
            help={t("Internal stuff, only shown for us")}
          >
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 4, maxRows: 8 }}
              style={{ width: "100%" }}
              placeholder={t("Enter...")}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label={t("Documents")} name={"medias"}>
            <UploaderFiles
              initialFiles={medias || []}
              onChange={(docs) => {
                form.setFieldValue(
                  "medias",
                  docs.map((doc) => ({
                    ...doc,
                    type: doc.type || DEFAULT_DOC_TYPE,
                  }))
                );
              }}
              itemRender={(originNode, file) => (
                <>
                  <Row align="bottom" gutter={12} wrap={false}>
                    <Col flex="auto">{originNode}</Col>
                    <Col flex="200px">
                      <label htmlFor={`type-${file.url}`}>{t("Type")}:</label>
                      <SelectAutocomplete
                        id={`type-${file.url}`}
                        value={getDocumentType(file.url)}
                        placeholder={t("Select")}
                        options={Object.values(DocumentTypeEnum).map((item) => ({
                          value: item,
                          label: item,
                        }))}
                        sortAlphabetically={false}
                        onChange={(val) => handleChangeDocumentType(val, file.url)}
                      />
                    </Col>
                  </Row>
                  <Typography.Text type="secondary">
                    {t("Created")}:{" "}
                    {moment(file.lastModifiedDate).format(DateFormats.FULL_DATE_TIME)}
                  </Typography.Text>
                  <Divider style={{ margin: "8px 0" }} />
                </>
              )}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item label={t("External Links")} name={"externalLinks"}>
            <Form.List name="externalLinks">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        rules={[{ required: true, whitespace: true, message: "Missing link" }]}
                      >
                        <Input placeholder="Link" size="large" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "type"]}
                        rules={[{ required: true, message: "Missing type" }]}
                      >
                        <SelectAutocomplete
                          placeholder={t("Type")}
                          options={Object.values(ExternalLinkTypeEnum).map((item) => ({
                            value: item,
                            label: item,
                          }))}
                          sortAlphabetically={false}
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t("Add")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
