import { EngagementDef } from "../types/super-admin.engagement.types";
import { deleteCached, getCached, putCached } from "./super-admin.api";

export const getEngagement = (companyId: string, workerProfileId: string) => {
  return getCached<EngagementDef>(`${companyId}-${workerProfileId}`, "/crafthunts/engagements", {
    companyId,
    workerProfileId,
  });
};

export const recommendCandidate = (companyId: string, workerProfileId: string) => {
  return putCached<EngagementDef>(`${companyId}-${workerProfileId}`, "/crafthunts/engagements", {
    companyId,
    workerProfileId,
  });
};

export const deleteRecommendation = (companyId: string, workerProfileId: string) => {
  return deleteCached<void>(`${companyId}-${workerProfileId}`, "/crafthunts/engagements", {
    companyId,
    workerProfileId,
  });
};
