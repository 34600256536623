import { HeaderContact } from "@app/components/pages/Candidates/components/CandidateDrawer/components/HeaderInfo/components/HeaderContact/HeaderContact";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { isProd } from "@app/constants/env";
import { CandidateDef } from "@app/features/super-admin/types/super-admin.candidate.types";
import { firebaseConfig } from "@app/firebaseConfig";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState } from "@app/store/store";
import { CrafthuntChat } from "@crafthunt-app/crafthunt-web-chat";
import { Drawer, Space } from "antd";
import { useTranslation } from "react-i18next";
import { MdMail, MdPhone } from "react-icons/md";
import { useSelector } from "react-redux";

type CandidateDrawerProps = {
  onClose: () => void;
  open: boolean;
  candidate: CandidateDef;
};

export const CandidateDrawer = ({ onClose, open, candidate }: CandidateDrawerProps) => {
  const superToken = useSelector((state: RootState) => state.superAdmin.superToken);
  const { i18n } = useTranslation();
  const currentTranslations = i18n.getDataByLanguage(i18n.language);
  return (
    <Drawer open={open} onClose={onClose}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <h2>
          {candidate.workerProfile.firstName} {candidate.workerProfile.lastName}
        </h2>
        <Space direction="vertical" size="middle">
          {!!candidate.workerProfile.phone && (
            <HeaderContact
              icon={MdPhone}
              href={`tel:${candidate.workerProfile.phone}`}
              label={candidate.workerProfile.phone}
            />
          )}
          {!!candidate.workerProfile.email && (
            <HeaderContact
              icon={MdMail}
              href={`mailto:${candidate.workerProfile.email}`}
              label={candidate.workerProfile.email}
            />
          )}
          {!superToken || !currentTranslations?.translation ? (
            <LoadingSpinner />
          ) : (
            <div style={{ width: "21rem", height: "70vh", marginTop: "16px" }}>
              <CrafthuntChat
                isStaging={!isProd()}
                jwt={superToken}
                firebaseConfig={firebaseConfig}
                translations={currentTranslations.translation}
                candidateId={candidate.id}
                lang={convertLangForBackend(i18n.language)}
                viewCandidateChatsAsSuperAdmin
                viewingCandidateWorkerProfileId={candidate.workerProfile.id}
              />
            </div>
          )}
        </Space>
      </Space>
    </Drawer>
  );
};
