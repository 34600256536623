import { generateShortLink } from "@app/api/short-link.api";
import { AddressPicker, EAddressPickerType } from "@app/components/ui/AddressPicker/AddressPicker";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { DateFormats } from "@app/constants/date.constants";
import { ENV, isDev } from "@app/constants/env";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import { mapUserToForm } from "@app/features/super-admin/helpers/super-admin.helper";
import {
  GetUsersParamsDef,
  UsersColumnsDataDef,
  UsersResponseDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { RootState, store } from "@app/store/store";
import { AddressObjDef } from "@app/types/address.types";
import {
  Badge,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Table,
  TableColumnsType,
  message,
} from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EngagementModal } from "./components/EngagementModal/EngagementModal";
import { UserModal } from "./components/UserModal/UserModal";

const { Search } = Input;

const LINK_KEY = "link";

export const Users = () => {
  const { t } = useTranslation();
  const { dispatch } = store;
  // table data
  const users = useSelector((state: RootState) => state.superAdmin.users);
  const usersCount = useSelector((state: RootState) => state.superAdmin.usersCount);
  const loading = useSelector((state: RootState) => state.loading.effects.superAdmin.getUsers);
  // table params
  const [keyword, setKeyword] = useState("");
  const [selectedTradeIds, setSelectedTradeIds] = useState([]);
  const [selectedJobTitleIds, setSelectedJobTitleIds] = useState([]);
  const [coordinates, setCoordinates] = useState("");
  const [page, setPage] = useState(0);
  // filter data
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  const jobTitleChoices = jobTitles.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  const tradesChoices = availableTradesTags.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  // modals
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [recommendationModalOpen, setRecommendationModalOpen] = useState(false);
  // data
  const selectedUser = useSelector((state: RootState) => state.superAdmin.selectedUser);

  const filterParams: Partial<GetUsersParamsDef> = {
    keyword,
    tradeIds: selectedTradeIds,
    jobTitleIds: selectedJobTitleIds,
    coordinates: coordinates,
  };

  const handleOnCancel = () => {
    setUserModalOpen(false);
  };

  const handleOnSave = async () => {
    dispatch.superAdmin.getUsers({
      limit: SUPER_ADMIN_PAGE_SIZE,
      offset: page,
      ...filterParams,
    });
    handleOnCancel();
  };

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number") {
      setPage(pagination.current - 1);

      dispatch.superAdmin.getUsers({
        limit: SUPER_ADMIN_PAGE_SIZE,
        offset: pagination.current - 1,
        ...filterParams,
      });
    }
  };

  const handleAddressChange = (address: AddressObjDef | null) => {
    if (address?.coordinates) {
      setCoordinates(`${address.coordinates.lat},${address.coordinates.lng}`);
    } else {
      setCoordinates("");
    }
  };

  const copyToClipboard = (value: string) => {
    try {
      navigator.clipboard.writeText(value);
      message.success({ content: t("Link copied!"), key: LINK_KEY, duration: 0.5 });
    } catch (err) {
      Modal.error({
        title: "Something went wrong, here is the raw link",
        content: value,
      });
      message.destroy(LINK_KEY);
    }
  };

  const generateShortMagicLink = async (magicLink: string) => {
    try {
      message.loading({ content: "Preparing magic link", key: LINK_KEY, duration: 0 });
      const shortMagicLink = isDev() ? magicLink : await generateShortLink(magicLink);
      copyToClipboard(shortMagicLink);
    } catch (error) {
      Modal.error({
        title: "Something went wrong, here is the raw link",
        content: magicLink,
      });
      message.destroy(LINK_KEY);
    }
  };

  const generatedData = (data: UsersResponseDef[]): UsersColumnsDataDef[] => {
    return data.map((item) => ({
      key: item.id,
      name: [item.firstName, item.lastName].filter(Boolean).join(" "),
      email: item.email || "",
      phone: item.phone || "",
      magicLink: (
        <Button onClick={() => generateShortMagicLink(item.magicLink)} type="link">
          {t("Copy")}
        </Button>
      ),
      profileLink: (
        <Button
          onClick={() => copyToClipboard(`${ENV.WEB_URL}/craftsmen/${item.slug}`)}
          type="link"
        >
          {t("Copy")}
        </Button>
      ),
      anonymousLink: (
        <Button
          onClick={() => copyToClipboard(`${ENV.WEB_URL}/craftsmen/${item.anonymousSlug}`)}
          type="link"
        >
          {t("Copy")}
        </Button>
      ),
      isVerified: (
        <Badge
          key="isVerified"
          status={item.isVerified ? "success" : "default"}
          text={t(item.isVerified ? "Yes" : "No")}
        />
      ),
      isCompanyAdmin: (
        <Badge
          key="isCompanyAdmin"
          status={item.isCompanyAdmin ? "success" : "default"}
          text={t(item.isCompanyAdmin ? "Yes" : "No")}
        />
      ),
      distance:
        typeof item.distance === "number"
          ? `${item.distance.toFixed(1).replace(".0", "")} km`
          : "-",
      createdAt: moment(item.createdAt).format(DateFormats.FULL_DATE_TIME),
      operation: (
        <Space size="middle">
          <a
            onClick={() => {
              setUserModalOpen(true);
              dispatch.superAdmin.setSelectedUser(mapUserToForm(item));
            }}
          >
            {t("Edit")}
          </a>
          <a
            onClick={() => {
              setRecommendationModalOpen(true);
              dispatch.superAdmin.setSelectedUser(mapUserToForm(item));
            }}
          >
            {t("recommend-button")}
          </a>
        </Space>
      ),
    }));
  };

  const columns: TableColumnsType<UsersColumnsDataDef> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 220,
    },
    { title: t("Email"), dataIndex: "email", key: "email", width: 150 },
    { title: t("Mobile phone"), dataIndex: "phone", key: "phone", width: 160 },
    { title: t("Magic link"), dataIndex: "magicLink", key: "magicLink", width: 120 },
    { title: t("Profile link"), dataIndex: "profileLink", key: "profileLink", width: 120 },
    { title: t("Anonymous link"), dataIndex: "anonymousLink", key: "anonymousLink", width: 150 },
    { title: t("Verified"), dataIndex: "isVerified", key: "isVerified", width: 110 },
    {
      title: t("Company Admin"),
      dataIndex: "isCompanyAdmin",
      key: "isCompanyAdmin",
      width: 120,
    },
    { title: t("distance-label"), dataIndex: "distance", key: "distance", width: 150 },
    { title: t("Created"), dataIndex: "createdAt", key: "createdAt" },
    { title: t("Actions"), dataIndex: "operation", key: "operation", fixed: "right", width: 200 },
  ];

  useEffect(() => {
    dispatch.superAdmin.getUsers({
      limit: SUPER_ADMIN_PAGE_SIZE,
      offset: 0,
      ...filterParams,
    });
    setPage(0);
  }, [JSON.stringify(filterParams)]);

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Search
          placeholder={t("Search for id, name, email, phone, slug, anonymousSlug and city")}
          enterButton={t("Search")}
          size="large"
          onSearch={setKeyword}
          allowClear
        />
        <Row gutter={[16, 16]} align="middle">
          <Col span={8}>
            <AddressPicker
              type={EAddressPickerType.CITY}
              disableMap
              onChange={handleAddressChange}
            />
          </Col>
          <Col span={8}>
            <SelectAutocomplete
              mode="multiple"
              placeholder={t("roles-select")}
              options={jobTitleChoices}
              onChange={(value) => setSelectedJobTitleIds(value)}
            />
          </Col>
          <Col span={8}>
            <SelectAutocomplete
              mode="multiple"
              placeholder={t("Trades")}
              options={tradesChoices}
              onChange={(value) => setSelectedTradeIds(value)}
            />
          </Col>
        </Row>
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={generatedData(users)}
          loading={loading}
          pagination={{
            current: page + 1,
            total: usersCount,
            showTotal: (total) => `${t("Total")} ${total} ${t("Users")}`,
            pageSize: SUPER_ADMIN_PAGE_SIZE,
            showSizeChanger: false,
            position: ["bottomLeft"],
          }}
          onChange={handlePageChange}
          scroll={{ x: 1700 }}
        />
      </Space>
      <UserModal
        open={userModalOpen}
        selectedUser={selectedUser}
        onSave={handleOnSave}
        onCancel={handleOnCancel}
      />
      <EngagementModal
        open={recommendationModalOpen}
        selectedUserId={selectedUser?.id}
        onClose={() => setRecommendationModalOpen(false)}
      />
    </>
  );
};
