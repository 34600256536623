import {
  CandidatesWithPaginationDef,
  GetCandidatesParamsDef,
} from "../types/super-admin.candidate.types";
import { superAdminApi } from "./super-admin.api";

export const getCandidates = (params: GetCandidatesParamsDef) => {
  return superAdminApi.get<CandidatesWithPaginationDef>("/super_admin/candidates", {
    params,
  });
};
